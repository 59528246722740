// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back {
    width: 90%;
    height: auto;
    padding: 10px;
    border-radius: 30% 50% 70% 30% / 30% 40% 70% 30%;
    position: relative;
    
}
.git {
    position: absolute;
    width: 170px;
    right: 20px;
    top: 230px;
    border-radius: 50%;
    border: 2px solid white;
}
.container {
    width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.side-by-side {
    display: flex;
    width: 100%;
}

.half {
    width: 50%;
    color: #ffffff;
    padding-top: 20px;
    padding-bottom: 30px;
    position: relative;
}
.half p{
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
}

.full-width {
    width: 100%;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 30px;
}

.full-width p{
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
}

.social-icons {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

.social-icons a {
    color: #ffffff;
    font-size: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,gDAAgD;IAChD,kBAAkB;;AAEtB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,UAAU;IACV,kBAAkB;IAClB,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,UAAU;IACV,cAAc;IACd,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".back {\n    width: 90%;\n    height: auto;\n    padding: 10px;\n    border-radius: 30% 50% 70% 30% / 30% 40% 70% 30%;\n    position: relative;\n    \n}\n.git {\n    position: absolute;\n    width: 170px;\n    right: 20px;\n    top: 230px;\n    border-radius: 50%;\n    border: 2px solid white;\n}\n.container {\n    width: 800px;\n    margin: 0 auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 20px;\n}\n\n.side-by-side {\n    display: flex;\n    width: 100%;\n}\n\n.half {\n    width: 50%;\n    color: #ffffff;\n    padding-top: 20px;\n    padding-bottom: 30px;\n    position: relative;\n}\n.half p{\n    color: #ffffff;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n.full-width {\n    width: 100%;\n    color: #ffffff;\n    padding-top: 10px;\n    padding-bottom: 30px;\n}\n\n.full-width p{\n    color: #ffffff;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n.social-icons {\n    display: flex;\n    justify-content: space-around;\n    margin-top: 30px;\n    margin-left: 20px;\n    margin-right: 20px;\n}\n\n.social-icons a {\n    color: #ffffff;\n    font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
