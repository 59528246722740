// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */
.background {
    width: 70%;
    height: 500px;
    background-image: linear-gradient(hsla(215, 100%, 51%, 0.55), hsla(252, 82%, 57%, 0.8));
    border-radius: 30% 70% 30% 70% / 70% 30% 70% 30%;
    filter: blur(100px);
    /* animation: rotate 10s cubic-bezier(0.8, 0.2, 0.2, 0.8) alternate infinite; */
    position: absolute;
    margin-top: -200px;
    z-index: -1;
}

`, "",{"version":3,"sources":["webpack://./src/components/Background/styles.css"],"names":[],"mappings":"AAAA;;;;;;;GAOG;AACH;IACI,UAAU;IACV,aAAa;IACb,uFAAuF;IACvF,gDAAgD;IAChD,mBAAmB;IACnB,+EAA+E;IAC/E,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;AACf","sourcesContent":["/* @keyframes rotate {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n} */\n.background {\n    width: 70%;\n    height: 500px;\n    background-image: linear-gradient(hsla(215, 100%, 51%, 0.55), hsla(252, 82%, 57%, 0.8));\n    border-radius: 30% 70% 30% 70% / 70% 30% 70% 30%;\n    filter: blur(100px);\n    /* animation: rotate 10s cubic-bezier(0.8, 0.2, 0.2, 0.8) alternate infinite; */\n    position: absolute;\n    margin-top: -200px;\n    z-index: -1;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
