// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    position: relative;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(10deg, purple, black); */
    background: linear-gradient(10deg, rgba(0, 0, 0, 0.5) 75%, hsla(215, 100%, 51%, 0.55));
   
    z-index: 1000;
}

.logo {
    width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uDAAuD;IACvD,sFAAsF;;IAEtF,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".header {\n    position: relative;\n    top: 0;\n    width: 100%;\n    height: 80px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    /* background: linear-gradient(10deg, purple, black); */\n    background: linear-gradient(10deg, rgba(0, 0, 0, 0.5) 75%, hsla(215, 100%, 51%, 0.55));\n   \n    z-index: 1000;\n}\n\n.logo {\n    width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
