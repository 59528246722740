// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuContainer {
    position: fixed;
    right: 0;
    top: 0;
    display: inline-block;
}

.menuButton {
    background-color: transparent;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.menuButton:hover,
.menuButton:focus {
    background-color: transparent;
}

.dropdownMenu {
    display: block;
    position: absolute;
    right: 0;
    background-color:transparent;
    min-width: 160px;
    z-index: -10;
    
}

.atual {
    display: block;
    position: absolute;
    height: 350px;
    min-width: 200px;
    top: -10px;
    right: -50px;
    /* background-color: hsla(252, 82%, 57%, 1); */
    background-image: linear-gradient(hsla(215, 100%, 51%, 1) 50%, hsla(252, 82%, 57%, 0.8) 50%);
    filter: blur(50px);
    z-index: -100;
}

.dropdownMenu a {
    color: rgb(255, 255, 255);
    margin: 10px;
    padding: 5px;
    text-decoration: none;
    display: block;
    text-align: center;
    font-weight: 500;
}

.dropdownMenu a:hover {
    font-weight: 700;
    background-color: hsla(252, 12%, 92%, 0.1);
    border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,MAAM;IACN,qBAAqB;AACzB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,aAAa;IACb,eAAe;IACf,YAAY;IACZ,eAAe;AACnB;;AAEA;;IAEI,6BAA6B;AACjC;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,QAAQ;IACR,4BAA4B;IAC5B,gBAAgB;IAChB,YAAY;;AAEhB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,YAAY;IACZ,8CAA8C;IAC9C,4FAA4F;IAC5F,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,0CAA0C;IAC1C,mBAAmB;AACvB","sourcesContent":[".menuContainer {\n    position: fixed;\n    right: 0;\n    top: 0;\n    display: inline-block;\n}\n\n.menuButton {\n    background-color: transparent;\n    color: white;\n    padding: 16px;\n    font-size: 16px;\n    border: none;\n    cursor: pointer;\n}\n\n.menuButton:hover,\n.menuButton:focus {\n    background-color: transparent;\n}\n\n.dropdownMenu {\n    display: block;\n    position: absolute;\n    right: 0;\n    background-color:transparent;\n    min-width: 160px;\n    z-index: -10;\n    \n}\n\n.atual {\n    display: block;\n    position: absolute;\n    height: 350px;\n    min-width: 200px;\n    top: -10px;\n    right: -50px;\n    /* background-color: hsla(252, 82%, 57%, 1); */\n    background-image: linear-gradient(hsla(215, 100%, 51%, 1) 50%, hsla(252, 82%, 57%, 0.8) 50%);\n    filter: blur(50px);\n    z-index: -100;\n}\n\n.dropdownMenu a {\n    color: rgb(255, 255, 255);\n    margin: 10px;\n    padding: 5px;\n    text-decoration: none;\n    display: block;\n    text-align: center;\n    font-weight: 500;\n}\n\n.dropdownMenu a:hover {\n    font-weight: 700;\n    background-color: hsla(252, 12%, 92%, 0.1);\n    border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
