import React from 'react';
import './styles.css'; // Importe o arquivo CSS para estilizar o contrato
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Privacy = () => {

    return (
        <>
            <Header />
            <div className="privacy-policy">
                <div className="contract">
                    <h1>
                        Política de Privacidade
                    </h1>
                    <p>
                        Agradecemos por utilizar os produtos e visitar o website do projeto Ludi Mundi, dedicado ao desenvolvimento de aplicativos e jogos mobile. Esta política de privacidade abrange tanto o uso de nossos produtos quanto a interação com nosso website, descrevendo como coletamos, usamos e protegemos as informações que você nos fornece. Ao utilizar nossos serviços ou visitar nosso website, você concorda com os termos descritos nesta política.
                    </p>
                    <h2>
                        1. Informações Coletadas
                    </h2>
                    <p>
                        Quando você interage com nossos produtos ou visita nosso website, coletamos informações automaticamente através de cookies e outras tecnologias de rastreamento. Essas informações podem incluir seu endereço IP, tipo de dispositivo, versão do sistema operacional, identificadores exclusivos de dispositivos e dados de uso.
                    </p>
                    <p>
                        Além disso, podemos coletar informações pessoais que você nos fornece voluntariamente, como nome, endereço de e-mail, número de telefone e outras informações de contato, bem como informações de pagamento ao realizar transações dentro de nossos produtos.
                    </p>
                    <h2>
                        2. Uso de Informações
                    </h2>
                    <p>
                        As informações coletadas são utilizadas para fornecer, manter, proteger e melhorar nossos produtos e serviços, além de personalizar sua experiência de usuário. Podemos usar suas informações para responder a solicitações, enviar comunicações de marketing, administrar concursos, pesquisas ou outras funcionalidades do site, e para outros fins comerciais legítimos.
                    </p>
                    <h2>
                        3. Proteção de Informações
                    </h2>
                    <p>
                        Nos comprometemos a proteger suas informações pessoais e a mantê-las seguras contra acesso não autorizado, uso indevido ou divulgação. Implementamos medidas de segurança físicas, eletrônicas e administrativas para proteger suas informações contra perdas, uso indevido e acesso não autorizado.
                    </p>
                    <h2>
                        4. Compartilhamento de Informações
                    </h2>
                    <p>
                        Em momento algum compartilhamos ou vendemos suas informações pessoais a terceiros, exceto quando exigido por lei ou quando necessário para fornecer serviços solicitados por você. No entanto, podemos compartilhar informações agregadas ou anonimizadas que não identifiquem pessoalmente os usuários para fins de análise, publicidade ou outros fins comerciais.
                    </p>
                    <h2>
                        5. Links para Outros Sites
                    </h2>
                    <p>
                        Nosso website e produtos podem conter links para outros sites que não são operados por nós. Não nos responsabilizamos pelo conteúdo ou práticas de privacidade desses sites de terceiros. Recomendamos que você revise as políticas de privacidade de todos os sites que visita.
                    </p>
                    <h2>
                        6. Alterações na Política de Privacidade
                    </h2>
                    <p>
                        Reservamo-nos o direito de atualizar ou modificar esta política de privacidade a qualquer momento. Quaisquer alterações serão publicadas nesta página, e a data da última atualização será revisada abaixo. Encorajamos você a revisar periodicamente esta política para estar informado sobre como protegemos suas informações.
                    </p>
                    <h2>
                        7. Contato
                    </h2>
                    <p>
                        Se você tiver dúvidas, preocupações ou comentários sobre esta política de privacidade, entre em contato conosco através do seguinte endereço de e-mail: support@ludimundi.com.
                    </p>
                    <p className="agreement">
                        Ao utilizar nosso web site e o aplicativos da Ludi Mundi, você concorda com os termos e condições desta Política de Privacidade.
                    </p>
                    <p>
                        <b>
                            Última atualização: 05 de Março de 2024.
                        </b>
                    </p>
                    <p>
                        Esta política de privacidade é eficaz a partir da data indicada acima e permanecerá em vigor, exceto em relação a quaisquer alterações em suas disposições no futuro, que entrarão em vigor imediatamente após serem publicadas nesta página.
                    </p>
                    <hr></hr>
                    <p>Consulte também a Política de Privacidade de nossos aplicativos:
                    <li>
                    Lie-Detector que atende a Lei de Proteção da Privacidade On-line das Crianças (COPPA). <a href='/appld-privacy-policy'>Clique aqui</a>
                    </li>
                    <li>
                    Virtual-Fidget que atende a Lei de Proteção da Privacidade On-line das Crianças (COPPA). <a href='/appvf-privacy-policy'>Clique aqui</a>
                    </li>
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Privacy;