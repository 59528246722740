import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaLinkedin, FaGithub, FaYoutube, FaInstagram, FaTwitch } from 'react-icons/fa';
import Montain from '../../assets/montain.png';
import Git from '../../assets/git.jpeg';
import './styles.css';
import AppSection from '../../components/AppSection';

const Home = () => {
    return (
        <>
            <Header />
            <div className="container">
                <div className="side-by-side">
                    <div className="half">
                        <img className='back' src={Montain} alt="Descrição da imagem" />
                        <img className='git' src={Git} alt="Foto" />
                        <div className="social-icons">
                            <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                <FaLinkedin />
                            </a>
                            <a href="https://github.com/" target="_blank" rel="noreferrer">
                                <FaGithub />
                            </a>
                            <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                                <FaYoutube />
                            </a>
                            <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                                <FaInstagram />
                            </a>
                            <a href="https://www.twitch.tv/" target="_blank" rel="noreferrer">
                                <FaTwitch />
                            </a>
                        </div>
                    </div>
                    <div className="half"><h1>Trabalhamos para evoluir o entretedimento digital</h1>
                        <p>Ludi Mundi é mais do que apenas um projeto de desenvolvimento de aplicativos e jogos móveis. É uma missão liderada por Edwin Bustillos, um desenvolvedor independente com uma visão singular de democratizar o acesso ao entretenimento digital.</p>
                        <p> Sua abordagem inovadora visa criar produtos de qualidade a preços acessíveis para uma ampla gama de usuários em todo o mundo.</p>
                        <p>Por trás dessa ideia está o desejo de não apenas criar jogos e aplicativos, mas também de construir uma comunidade de usuários engajados e entusiastas. Ele entende que, ao tornar seus produtos acessíveis, não apenas alcança mais pessoas, mas também constrói uma base sólida de suporte e feedback, essencial para o crescimento contínuo e aprimoramento dos produtos do Ludi Mundi.</p>
                    </div>
                </div>
                <div className="full-width"> <p>A filosofia por trás do Ludi Mundi é refletida no próprio nome do projeto, que significa "jogos para o mundo". Acreditamos firmemente que o entretenimento digital deve ser inclusivo e acessível a todos, independentemente de sua localização geográfica ou situação financeira. Ele vê o Ludi Mundi como uma maneira de unir pessoas por meio da diversão e da criatividade, criando experiências compartilhadas que transcendem fronteiras e culturas.</p>
                </div>
                <AppSection />
            </div>
            <Footer />
        </>
    );
};

export default Home;