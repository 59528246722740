import React from 'react';
import './styles.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const FidgetPrivacy = () => {

    return (
        <> 
        <Header />
        <div className="privacy-policy">
            <div className="contract">
                <h1>
                    Política de Privacidade do Aplicativo "Virtual-Fidget"
                </h1>
                <p className="last-update">
                    Última atualização: 18 de Março de 2024
                </p>
                <p>
                    Agradecemos por utilizar o aplicativo "Virtual Fidget". Esta Política de Privacidade tem como objetivo informar sobre nossas práticas em relação à coleta, uso e divulgação de informações pessoais, conforme exigido pela Lei de Proteção da Privacidade On-line das Crianças (COPPA).
                </p>
                <h2>
                    1. Informações Coletadas
                </h2>
                <p>
                    Nosso aplicativo não coleta, armazena ou compartilha informações pessoais de qualquer usuário, especialmente de crianças menores de 13 anos, em conformidade com a COPPA.
                </p>
                <h2>
                    2. Uso de Informações
                </h2>
                <p>
                    Não utilizamos qualquer informação pessoal, uma vez que o aplicativo não coleta dados de nenhum tipo. Todas as interações com o aplicativo são locais e não são compartilhadas com terceiros.
                </p>
                <h2>
                    3. Coleta de Informações Automáticas
                </h2>
                <p>
                    Não coletamos automaticamente informações sobre o dispositivo ou o uso do aplicativo. Não utilizamos cookies, registros de servidor ou tecnologias semelhantes para rastrear ou armazenar informações sobre os usuários.
                </p>
                <h2>
                    4. Segurança
                </h2>
                <p>
                    Nós nos empenhamos em proteger a privacidade dos usuários. O aplicativo "Virtual Fidget" é projetado para funcionar exclusivamente offline, sem a necessidade de conexão com a internet ou transmissão de dados. Isso garante que nenhuma informação seja compartilhada ou comprometida.
                </p>
                <h2>
                    5. Direitos dos Pais
                </h2>
                <p>
                    Reconhecemos a importância da proteção da privacidade das crianças. Se um pai ou responsável legal descobrir que uma criança menor de 13 anos forneceu informações pessoais através do nosso aplicativo, encorajamos entrar em contato conosco imediatamente para que possamos tomar medidas para remover tais informações.
                </p>
                <h2>
                    6. Alterações nesta Política de Privacidade
                </h2>
                <p>
                    Esta Política de Privacidade pode ser atualizada ocasionalmente para refletir mudanças nas práticas do aplicativo ou em conformidade com as leis e regulamentos aplicáveis. Recomendamos que os usuários revisem periodicamente esta política para estar cientes de como estamos protegendo a privacidade dos usuários.
                </p>
                <h2>
                    7. Contato
                </h2>
                <p>
                    Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco através do seguinte endereço de e-mail: support@ludimundi.com.
                </p>
                <p className="agreement">
                    Ao utilizar o aplicativo "Virtual Fidget", você concorda com os termos e condições desta Política de Privacidade.
                </p>
            </div>
        </div>
        <Footer />
        </>
    );
}

export default FidgetPrivacy;