import React, { useState } from 'react';
import './styles.css';

const MenuBurguer = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="menuContainer">
            <button onClick={() => setIsOpen(!isOpen)} className="menuButton">
                Menu
            </button>

            {isOpen && (<>
                <div className='atual'></div>
                <div className="dropdownMenu">
                        <a href="/home" >Home</a>
                        <a href="/privacy-policy">Politica de Privacidade</a>
                        
                </div>
            </>
            )}
        </div>
    );
}

export default MenuBurguer;