import React from 'react';
import './styles.css';

const Background = () => {
    return (
        <div className="background">
        </div>
        );
    }
export default Background;
