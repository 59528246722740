import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import LiePrivacy from './pages/Lie-Privacy';
import FidgetPrivacy from './pages/Fidget-Privacy';

function App() {
  return (
    <BrowserRouter basename="/">
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/appld-privacy-policy" element={<LiePrivacy />} />
      <Route path="/appvf-privacy-policy" element={<FidgetPrivacy />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
