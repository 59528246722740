import React from 'react';
//import Montain from '../../assets/montain.png';
//import Git from '../../assets/git.jpeg';
import { Carousel } from 'react-responsive-carousel';
import QRCode from '../../assets/lie/lie-qrcode.png';
import DownloadLink from '../../assets/play-store.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import './styles.css';

import lie1 from '../../assets/lie/1.png';
import lie2 from '../../assets/lie/2.png';
import lie3 from '../../assets/lie/3.png';
import lie4 from '../../assets/lie/4.png';
import lie5 from '../../assets/lie/5.png';
import lie6 from '../../assets/lie/6.png';
import lie7 from '../../assets/lie/7.png';
import lie8 from '../../assets/lie/8.png';


const AppSection = () => {
    
    const lie = [lie1, lie2, lie3, lie4, lie5, lie6, lie7, lie8];

    return (
        <div className="app-section">
        <h2>Verdade ou Mentira</h2>
        <div className="app-content">
            <div className="carousel">
                <Carousel showThumbs={false} showStatus={false} >
                    {lie.map((image, index) => (
                        <div key={index} className="slide">
                            <img src={image} alt="Descrição da imagem" />
                        </div>
                    ))}
                </Carousel>
            </div>
            <div className="right-column">
                <p>O "Verdade" ou "Mentira" é mais do que apenas um aplicativo de entretenimento é uma experiência interativa que proporciona momentos de diversão e suspense para todas as idades.</p>
                <img src={QRCode} style={{ width: '100px', backgroundColor: "#FFFFFF", padding: "5px", borderRadius:10 }} alt="QR Code" />
                <a href="https://play.google.com/store/apps/details?id=com.ludimundi.lie" download>
                    <img src={DownloadLink} style={{width:'150px'}} alt="Link de Download" />
                </a>
                <a href='/appld-privacy-policy'>Política de Privacidade</a>
            </div>
        </div>
    </div>
    );
};

export default AppSection;