// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    width: '100%';
    padding-top: 10px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    z-index: 100;
    
}
.contract {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    width: 500px;
    color: white;}
p{
    text-align: justify;
}

.privacy-policy h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.privacy-policy h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
}

.privacy-policy p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
}
.privacy-policy .agreement {
    font-weight: bold;
    margin-top: 20px;
}
.last-update {
    font-size: 14px;
    color: #f4f4f4;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Lie-Privacy/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,YAAY;;AAEhB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,YAAY,CAAC;AACjB;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".privacy-policy {\n    display: flex;\n    flex-direction: column;\n    font-family: Arial, sans-serif;\n    color: #333;\n    padding: 20px;\n    width: '100%';\n    padding-top: 10px;\n    align-items: center;\n    justify-content: center;\n    justify-items: center;\n    z-index: 100;\n    \n}\n.contract {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    padding: 20px;\n    width: 500px;\n    color: white;}\np{\n    text-align: justify;\n}\n\n.privacy-policy h1 {\n    font-size: 24px;\n    margin-bottom: 20px;\n}\n\n.privacy-policy h2 {\n    font-size: 20px;\n    margin-top: 20px;\n    margin-bottom: 10px;\n    width: 100%;\n    text-align: left;\n}\n\n.privacy-policy p {\n    font-size: 16px;\n    line-height: 1.5;\n    margin-bottom: 10px;\n}\n.privacy-policy .agreement {\n    font-weight: bold;\n    margin-top: 20px;\n}\n.last-update {\n    font-size: 14px;\n    color: #f4f4f4;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
