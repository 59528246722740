import React, { useState }  from 'react';
import { Link } from "react-router-dom";
import './styles.css';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <footer>
      <div className="footerSeparator"></div>
      <div className="footerButtons">
        <Link className="neonButton" to='/home'>Home</Link>
        <Link className="neonButton" to='/home' onClick={openModal}>Contato</Link>
        <Link className="neonButton" to='/privacy-policy'>Politica de Privacidade</Link>
      </div>
        <div className="footerText">
            <p>© 2024 Ludi Mundi. All rights reserved.</p>
        </div>
        {isModalOpen && (
        <div className="modal">
          <div className="modal-content" onClick={closeModal}>
          <h2>Entre em Contato Conosco</h2>
          <hr></hr>
          <p>Se você encontrou algum problema em nossos produtos ou tem sugestões para melhorias, estamos aqui para ajudar! Nosso canal de "Entre em Contato Conosco" é a sua porta de entrada para correções, traduções, suporte e solução de bugs.</p>
          <p>Nossa equipe dedicada está pronta para ouvir suas preocupações e trabalhar rapidamente para resolver qualquer problema que você possa encontrar. Queremos garantir que sua experiência conosco seja a melhor possível, e isso inclui garantir que nossos produtos funcionem perfeitamente para você.</p>
          <a href='mailto:support@ludimundi.com'>support@ludimundi.com</a>
          <br></br>
          <button className='neonButton' onClick={closeModal}>Fechar</button>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;